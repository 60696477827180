<template>
  <section id="user-list">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="3">
              <b-form-group
                label="Nome"
                label-for="filter_name"
              >
                <b-form-input
                  id="filter_name"
                  v-model="filtersValues.name"
                  @keyup.enter="getPlans"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Status"
                label-for="filter_status"
              >
                <b-form-select
                  id="filter_status"
                  v-model="filtersValues.deleted_at"
                  :options="filtersOptions.deleted_at"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="3"
              class="form-buttons"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  @click="searchAndResetPage"
                >
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card>
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Resultados
              </h4>
            </b-col>

            <b-col class="text-right">
              <b-button
                class="mr-1"
                variant="primary"
                :to="{ name: 'cms-commercia-plan-additionals' }"
              >
                Acessar Addons
              </b-button>

              <b-button
                variant="primary"
                :to="{ name: 'cms-commercia-plan-form' }"
              >
                <span class="text-nowrap">Adicionar Plano</span>
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-table
                :busy="isLoading"
                fixed
                :items="results"
                :fields="result_fields"
                sort-icon-left
                no-local-sorting
                :sort-by.sync="filtersValues.sortBy"
                :sort-desc.sync="filtersValues.sortDesc"
                @sort-changed="sortingChanged"
              >
                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #cell(name)="row">
                  <span class="d-block">{{ row.item.name }}</span>
                </template>

                <template #cell(interval)="row">
                  <span>{{ row.item.interval_quantity }} {{ $t(`commercia.plans.columns.interval_type.${row.item.interval_type}`) }}</span>
                </template>

                <template #cell(price)="row">
                  <span>{{ row.item.price | currency }}</span>
                </template>

                <template #cell(status)="row">
                  <span>{{ row.item.deleted_at ? 'Removido' : 'Ativo' }}</span>
                </template>

                <template #cell(actions)="row">
                  <div class="text-nowrap text-center">
                    <b-button
                      v-if="$can('read', 'Commercia')"
                      variant="flat-primary"
                      :to="{ name: 'cms-commercia-plan-form', params: { id: row.item.id } }"
                      title="Editar"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>

                    <b-button
                      v-if="$can('delete', 'Commercia')"
                      variant="flat-primary"
                      title="Deletar"
                      :disabled="row.item.deleted_at !== null"
                      @click="deletePlan(row.item.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                  </div>
                </template>

                <template #row-details="row">
                  <b-spinner v-if="!row.item.message" />
                  <p>{{ row.item.message }}</p>
                </template>

              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                :link-gen="() => { return '#' }"
                align="end"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

    </b-row>

  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserService from '@/services/userService'
import { formatDateTimeDbToView } from '@/utils/helpers'
// import moment from 'moment'
import {
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BPaginationNav,
  BSpinner,
  BButton,
  // BModal,
  BCard,
  BBreadcrumb,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import toast from '@/mixins/toast'
import CommerciaPlanService from '@/services/commerciaPlanService'

export default {
  components: {
    BCardActions,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BPaginationNav,
    BSpinner,
    BButton,
    // BModal,
    BBreadcrumb,
    // vSelect,
  },

  mixins: [toast],

  data() {
    return {
      userService: null,
      isLoading: true,
      breadcrumb: [
        {
          text: 'CMS',
          active: true,
        },
        {
          text: 'Commercia',
          active: true,
        },
        {
          text: 'Planos',
          to: { name: 'cms-commercia-plans' },
        },
      ],

      filtersInitialValues: {},
      filtersValues: {
        name: '',
        deleted_at: 0,
        sortBy: 'id',
        sortDesc: true,
      },
      filtersOptions: {
        deleted_at: [
          { value: 0, text: 'Somente Ativos' },
          { value: 1, text: 'Todos' },
        ],
      },

      pages: 1,
      currentPage: 1,
      results: [],
      result_fields: [],
      formatDateTimeDbToView,
    }
  },

  watch: {
    currentPage() {
      this.getPlans()
    },
  },

  created() {
    this.userService = new UserService()
    this.getPlans()
    this.filtersInitialValues = { ...this.filtersValues }

    this.result_fields = [
      { key: 'id', label: 'ID', sortable: true, thStyle: 'width: 100px' },
      { key: 'name', label: 'Nome', sortable: true },
      { key: 'interval', label: 'Intervalo', sortable: true },
      { key: 'status', label: 'Status', sortable: true },
      { key: 'price', label: 'Preço', sortable: true },
    ]

    if (this.showActions()) {
      this.result_fields.push({ key: 'actions', sortable: false, thStyle: 'width: 150px' })
    }
  },

  methods: {
    reset() {
      this.filtersValues = JSON.parse(JSON.stringify(this.filtersInitialValues))
      this.searchAndResetPage()
    },

    searchAndResetPage() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.getPlans()
      }
    },

    sortingChanged(ctx) {
      this.filtersValues.sortBy = ctx.sortBy
      this.filtersValues.sortDesc = ctx.sortDesc
      this.getPlans()
    },

    showActions() {
      return this.$can('read', 'Commercia') || this.$can('update', 'Commercia') || this.$can('delete', 'Commercia')
    },

    deletePlan(planId) {
      this.$swal({
        title: 'Deletar plano?',
        text: 'O plano não poderá mais ser contrato! Usuários com o plano ativo poderão continuar com ele',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          CommerciaPlanService.deletePlan(planId).then(response => {
            this.isLoading = false
            if (response.status === 200) {
              this.results = this.results.filter(filter => filter.id !== planId)
              this.toastSuccess(`Plano ${planId} removido!`, '')
            }
          }).catch(error => {
            this.isLoading = false
            this.toastDanger('Erro!', error.response.data.message)
          })
        }
      }).catch(error => {
        this.isLoading = false
        this.toastDanger('Erro!', error.response.data.message)
      })
    },

    getPlans() {
      this.isLoading = true

      CommerciaPlanService.listPlans(this.filtersValues, this.currentPage).then(response => {
        if (response.data !== null) {
          this.results = response.data.data.plans.data
          this.pages = response.data.data.plans
          delete this.pages.data
        }
        this.isLoading = false
      })
    },

  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #user-list {
    .b-table{
      td{
        padding: 0.5rem 1rem;
      }
    }

    .multiple-lines{
      display:block;
    }

    .multiple-lines-email{
      margin-top: 5px;
      display:block;
    }

    .header-card {
      margin-bottom: 20px;
    }

    .form-buttons button {
      margin-right: 10px;
    }
  }
</style>
